import { saveAs } from "file-saver";
import definititions from "./definitions";
import { LocalePathMap, Locales, LocaleType } from "models/locale";

const getUrlFor = (path: string) =>
  `https://www.stage.masterenglish.com/${path}`;

const options = { type: "text/xml;charset=utf-8" };

export const downloadSitemapXML = () => {
  // gather a list of urls to show in sitemap
  const entryList: Record<LocaleType, string[]> = {
    "es-419": [],
    "en-US": [],
    "de-DE": [],
    "it-IT": [],
    "pt-BR": [],
  };

  definititions.forEach((route) => {
    const { sitemap = false, pathSets } = route;
    if (!sitemap) {
      return;
    }

    for (const uncastedLocale in pathSets) {
      const locale = uncastedLocale as LocaleType;
      const localePath = LocalePathMap[locale];
      const set = pathSets[locale];
      if (set) {
        let { path } = set;
        if (path !== "") {
          path = `${path}/`;
        }
        // if loc already exists, ignore this round
        const url = getUrlFor(`${localePath}/${path}`);
        const entry = ["  <url>", `    <loc>${url}</loc>`];

        // iterate to print all language versions
        for (const uncastedLoc in pathSets) {
          const locale = uncastedLoc as LocaleType;

          // ignore non supported locales
          if (Locales.includes(locale) === false) {
            continue;
          }

          // process subset
          const subset = pathSets[locale];
          if (subset) {
            let { path } = subset;
            if (path !== "") {
              path = `${path}/`;
            }
            const url = getUrlFor(`${LocalePathMap[locale]}/${path}`);
            entry.push(
              `    <xhtml:link rel="alternate" hreflang="${locale}" href="${url}" />`,
            );
          }
        }

        // xml ending
        entry.push("  </url>\n");
        entryList[locale].push(entry.join("\n"));
      }
    }
  });

  // process per language sitemaps
  for (const locale of Locales) {
    const localePath = LocalePathMap[locale];
    const langSitemap = [
      `<?xml version="1.0" encoding="UTF-8"?>\n`,
      `<urlset xmlns="http://www.stage.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">\n`,
      ...entryList[locale].map((entry) => entry),
      `</urlset>`,
    ];
    saveAs(new Blob(langSitemap, options), `sitemap-${localePath}.xml`);
  }

  // file contents
  const mainSitemap = [
    `<?xml version="1.0" encoding="UTF-8"?>\n`,
    `<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">\n`,
    ...Locales.map((locale) => {
      const url = getUrlFor(`sitemap-${LocalePathMap[locale]}.xml`);
      return `  <sitemap>\n    <loc>${url}</loc>\n  </sitemap>\n`;
    }),
    `</sitemapindex>`,
  ];

  // save to file
  saveAs(new Blob(mainSitemap, options), "sitemap.xml");
};
