export type Device = "mobile" | "tablet" | "desktop";

export const breakpoints: Record<Device, number> = {
  mobile: 376,
  tablet: 768,
  desktop: 992,
};

export default {
  mobile: `(min-width: ${breakpoints.mobile}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
};
