import { CouponModel } from "./coupon";
import { LocaleType } from "./locale";
import { ProgramPlan } from "./plan";
import { ProductConfiguration } from "./product";
import { QuestionModel } from "./question";
import { ThemeType } from "./theme";

export const STATE_KEY = "state";

export enum QuestionnaireVersion {
  // email discount variant
  V69 = "69",

  // tv campaign (and landing page embedded)
  V73 = "73",

  // white program page test
  V75 = "75",

  // web questionnaire improvements
  V76 = "76",

  // paddle billing test
  V78 = "78",

  // chatbot-in-questionnaire
  V79 = "79",

  // special cases, these variants redirect user to masterenglish.ai domain
  AI = "ai",
  AIMX = "aimx",
}

export const QUESTIONNAIRE_KEY = "questionnaire";

export const VERSION_KEY = "version";

export const TARGET_KEY = "target";

// every marked has the same supported versions
export const SupportedQuestionnaires: QuestionnaireVersion[] = [
  QuestionnaireVersion.V69,
  QuestionnaireVersion.V73,
  QuestionnaireVersion.V75,
  QuestionnaireVersion.V76,
  QuestionnaireVersion.V78,
  QuestionnaireVersion.V79,
];

export const ControlQuestionnaireMap: Record<LocaleType, QuestionnaireVersion> =
  {
    "es-419": QuestionnaireVersion.V76,
    "pt-BR": QuestionnaireVersion.V78,
    "en-US": QuestionnaireVersion.V76,
    "it-IT": QuestionnaireVersion.V76,
    "de-DE": QuestionnaireVersion.V76,
  };

export const EmbeddedQuestionnaireMap: Record<
  LocaleType,
  QuestionnaireVersion
> = {
  "es-419": QuestionnaireVersion.V73,
  "pt-BR": QuestionnaireVersion.V78,
  "en-US": QuestionnaireVersion.V76,
  "it-IT": QuestionnaireVersion.V76,
  "de-DE": QuestionnaireVersion.V76,
};

// list of hot jsons (won't be cached)
export const areJsonHot = () => import.meta.env.VITE_PUBLIC_HOT_JSON === "true";

export const EmbeddedQuestionnaireQuestion = 2;
export const EmbeddedQuestionnaireFollowUpQuestion = 3;

interface ProgressModel {
  type?: "navbar" | "top"; // top means wide bar under the navbar
  start: number;
  end: number; // this one should be ThankYouView id
}

export type UserLevelAlgorithm = "LevelQuestion" | "InitialLevel";

export interface QuestionnaireModel {
  id: QuestionnaireVersion;
  initialQuestion: number;
  questions: QuestionModel[];

  // optional, in some cases
  userLevelAlgorithm?: UserLevelAlgorithm;
  userLevelMappings?: UserLevelMapping[];

  // progress bar definition
  progress: ProgressModel;

  // to force set theme
  theme?: ThemeType;

  // to customise product configurations
  productConfigurations?: ProductConfiguration[];

  // new since v23
  lastLevelQuestionFollowUpQuestion?: number;
  levelQuestionRules?: LevelQuestionRule[];

  // used to overwrite study schedule selection
  programPlan?: ProgramPlan;

  // if we want to use paddle coupons
  coupon?: CouponModel;
}

// the values are minimums
export interface LevelQuestionRule {
  no?: number;
  maybe?: number;
  followUpQuestion: number;
}

export type UserLevelType =
  | "beginner"
  | "elementary"
  | "basic"
  | "intermediate"
  | "upperintermediate"
  | "advanced";

interface UserLevelMapping {
  userLevel: UserLevelType;
  first?: number;
  last?: number;
}
