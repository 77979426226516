import { LocaleType } from "models/locale";

// wrappers
import HandleYourSubscriptionViewWrapper from "views/subscription/handle-your-subscription/wrapper";
import HomeViewWrapper from "views/home/wrapper";
import QuestionnaireRaffleViewWrapper from "views/questionnaire/raffle/wrapper";
import QuestionnaireVersionViewWrapper from "views/questionnaire/version/wrapper";
import QuestionnaireQuestionViewWrapper from "views/questionnaire/question/wrapper";
import ForgotPasswordViewWrapper from "views/forgot-password/forgot/wrapper";
import RecoverPasswordViewWrapper from "views/forgot-password/recover/wrapper";
import AppDashboardViewWrapper from "views/app/dashboard/wrapper";
import AppLinkViewWrapper from "views/app/link/wrapper";
import AppExerciseViewWrapper from "views/app/exercise/wrapper";
import TermsOfUseViewWrapper from "views/legal/terms-of-use/wrapper";
import PrivacyPolicyViewWrapper from "views/legal/privacy-policy/wrapper";
import LicensesViewWrapper from "views/legal/licenses/wrapper";
import LinkLoginViewWrapper from "views/link/login/wrapper";
import PaymentFailureViewWrapper from "views/payment/failure/wrapper";
import QuestionnaireGenerateViewWrapper from "views/questionnaire/generate/wrapper";
import QuestionnaireResetViewWrapper from "views/questionnaire/reset/wrapper";
import QuestionnaireUpdateEmailViewWrapper from "views/questionnaire/update-email/wrapper";
import QuestionnaireEmailExistsViewWrapper from "views/questionnaire/email-exists/wrapper";
import QuestionnaireStartLevelTestViewWrapper from "views/questionnaire/start-level-test/wrapper";
import QuestionnaireCongratulationsViewWrapper from "views/questionnaire/congratulations/wrapper";
import QuestionnaireThankYouViewWrapper from "views/questionnaire/thank-you/wrapper";
import QuestionnaireNewConfirmViewWrapper from "views/questionnaire/new-confirm/wrapper";
import QuestionnairePostPurchaseViewWrapper from "views/questionnaire/post-purchase/wrapper";
import QuestionnaireReactivateOrNewViewWrapper from "views/questionnaire/reactivate-or-new/wrapper";
import QuestionnaireReactivateViewWrapper from "views/questionnaire/reactivate/wrapper";
import QuestionnaireNextStepViewWrapper from "views/questionnaire/next-step/wrapper";
import QuestionnaireInitialLevelTestViewWrapper from "views/questionnaire/initial-level-test/wrapper";
import QuestionnaireLoginWithPasswordViewWrapper from "views/questionnaire/login-with-password/wrapper";
import QuestionnaireVerifyEmailViewWrapper from "views/questionnaire/verify-email/wrapper";
import LoginFormViewWrapper from "views/login/form/wrapper";
import LoginSuccessViewWrapper from "views/login/success/wrapper";
import LoginExpiredViewWrapper from "views/login/expired/wrapper";
import LoginFailureViewWrapper from "views/login/failure/wrapper";
import LoginExistsViewWrapper from "views/login/exists/wrapper";

export interface RouteHandle {
  name?: RouteNameType;
  locale?: LocaleType;
  canonical?: string;
  sitemap?: boolean;
  indexable?: boolean;
}

export interface RoutePath {
  // path for this locale version
  path: string;

  // optional element, can be used to override the main component
  OverrideViewCompoment?: JSX.Element;
}

// pathSet type for the route
export type RoutePathSet = Partial<Record<LocaleType, RoutePath | undefined>>;

export type RouteNameType =
  | "home"
  | "handle-your-sub"
  | "privacy"
  | "terms"
  | "licenses"
  | "link-login"
  | "login-link"
  | "login-link-sent"
  | "login-expired"
  | "login-failed"
  | "login-account-exists"
  | "raffle"
  | "generate"
  | "reset"
  | "forgot-password"
  | "recover-password"
  | "account-exists"
  | "app-dashboard"
  | "app-exercise"
  | "app-forgot-password"
  | "questionnaire"
  | "question"
  | "questionnaire-post-purchase"
  | "questionnaire-thank-you"
  | "questionnaire-verify-email"
  | "questionnaire-update-email"
  | "questionnaire-email-exists"
  | "questionnaire-congratulations"
  | "questionnaire-next-step"
  | "questionnaire-start-level-test"
  | "questionnaire-initial-level-test"
  | "questionnaire-reactivate-or-new"
  | "questionnaire-reactivate"
  | "questionnaire-new-confirm"
  | "questionnaire-login-with-password"
  | "payment-failed";

export interface RouteDefinition {
  // just a name for the route
  name: RouteNameType;

  // the view to render
  ViewComponent: JSX.Element;

  // if true, the generated route definition will be lazy loaded
  lazy?: boolean; // NOT IN USE RIGHT NOW

  // if something else than undefined, do not create locale prefixed urls
  root?: string;

  // if not undefined, we first take the user to a wrapper that detects the language
  detect?: string;

  // each language version of the path
  pathSets?: RoutePathSet;

  // if true, shows on sitemap (default FALSE)
  sitemap?: boolean;

  // if true, should be visible to crawlers - if false, not (default FALSE)
  indexable?: boolean;
}

const definititions: RouteDefinition[] = [
  {
    name: "home",
    ViewComponent: <HomeViewWrapper />,
    sitemap: true,
    indexable: true,
    detect: "",
    pathSets: {
      "es-419": { path: "" },
      "pt-BR": { path: "" },
      "en-US": { path: "" },
      "it-IT": { path: "" },
    },
  },
  {
    name: "handle-your-sub",
    ViewComponent: <HandleYourSubscriptionViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      "es-419": { path: "gestiona-tu-plan" },
      "pt-BR": { path: "gerencie-sua-assinatura" },
      "en-US": { path: "handle-your-subscription" },
      "it-IT": { path: "gestisci-il-tuo-abbonamento" },
    },
  },
  {
    name: "terms",
    ViewComponent: <TermsOfUseViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      "es-419": { path: "terminos-de-uso" },
      "pt-BR": { path: "termos-de-uso" },
      "en-US": { path: "terms-of-use" },
      "it-IT": { path: "termini-d-uso" },
    },
  },
  {
    name: "privacy",
    ViewComponent: <PrivacyPolicyViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      "es-419": { path: "politica-de-privacidad" },
      "pt-BR": { path: "politica-de-privacidade" },
      "en-US": { path: "privacy-policy" },
      "it-IT": { path: "informativa-sulla-privacy" },
    },
  },
  {
    name: "licenses",
    ViewComponent: <LicensesViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      "es-419": { path: "licencias" },
      "pt-BR": { path: "licencas" },
      "en-US": { path: "licenses" },
      "it-IT": { path: "licenze" },
    },
  },
  {
    name: "login-link",
    ViewComponent: <LoginFormViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      "es-419": { path: "inicio-de-sesion" },
      "pt-BR": { path: "fazer-login" },
      "en-US": { path: "login" },
      "it-IT": { path: "accedi" },
    },
  },
  {
    name: "login-link-sent",
    ViewComponent: <LoginSuccessViewWrapper />,
    pathSets: {
      "es-419": { path: "inicio-de-sesion/link" },
      "pt-BR": { path: "fazer-login/link" },
      "en-US": { path: "login/link" },
      "it-IT": { path: "accedi/collegamento" },
    },
  },
  {
    name: "login-expired",
    ViewComponent: <LoginExpiredViewWrapper />,
    pathSets: {
      "es-419": { path: "inicio-de-sesion/caducado" },
      "pt-BR": { path: "fazer-login/expirado" },
      "en-US": { path: "login/expired" },
      "it-IT": { path: "accedi/scaduto" },
    },
  },
  {
    name: "login-failed",
    ViewComponent: <LoginFailureViewWrapper />,
    pathSets: {
      "es-419": { path: "inicio-de-sesion/fallido" },
      "pt-BR": { path: "fazer-login/falha" },
      "en-US": { path: "login/failure" },
      "it-IT": { path: "accedi/fallito" },
    },
  },
  {
    name: "login-account-exists",
    ViewComponent: <LoginExistsViewWrapper />,
    pathSets: {
      "es-419": { path: "inicio-de-sesion/cuenta-existe" },
      "pt-BR": { path: "fazer-login/conta-existe" },
      "en-US": { path: "login/account-exists" },
      "it-IT": { path: "accedi/account-esiste" },
    },
  },
  {
    name: "link-login",
    ViewComponent: <LinkLoginViewWrapper />,
    pathSets: {
      "es-419": { path: "link/login" },
      "pt-BR": { path: "link/login" },
      "en-US": { path: "link/login" },
      "it-IT": { path: "link/login" },
    },
  },
  {
    name: "forgot-password",
    ViewComponent: <ForgotPasswordViewWrapper />,
    sitemap: true,
    indexable: true,
    pathSets: {
      // these are coming from the backend with language prefixed (for example pt-br/forgot-password)
      "es-419": { path: "forgot-password" },
      "pt-BR": { path: "forgot-password" },
      "en-US": { path: "forgot-password" },
      "it-IT": { path: "forgot-password" },
    },
  },
  {
    name: "recover-password",
    ViewComponent: <RecoverPasswordViewWrapper />,
    pathSets: {
      "es-419": { path: "recuperacion-de-contrasena" },
      "pt-BR": { path: "recuperacao-de-senha" },
      "en-US": { path: "recover-password" },
      "it-IT": { path: "recupera-password" },
    },
  },
  {
    name: "app-dashboard",
    ViewComponent: <AppDashboardViewWrapper />,
    root: "app/dashboard",
  },
  {
    name: "app-exercise",
    ViewComponent: <AppExerciseViewWrapper />,
    root: "app/exercise",
  },
  {
    name: "app-forgot-password",
    ViewComponent: <AppLinkViewWrapper />,
    root: "app/forgot-password",
  },
  {
    name: "raffle",
    ViewComponent: <QuestionnaireRaffleViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles" },
      "pt-BR": { path: "fale-ingles" },
      "en-US": { path: "speak-english" },
      "it-IT": { path: "parla-inglese" },
    },
  },
  {
    name: "generate",
    ViewComponent: <QuestionnaireGenerateViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/genarate" },
      "pt-BR": { path: "fale-ingles/genarate" },
      "en-US": { path: "speak-english/generate" },
      "it-IT": { path: "parla-inglese/genera" },
    },
  },
  {
    name: "reset",
    ViewComponent: <QuestionnaireResetViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/reset" },
      "pt-BR": { path: "fale-ingles/reset" },
      "en-US": { path: "speak-english/reset" },
      "it-IT": { path: "parla-inglese/reset" },
    },
  },
  {
    name: "questionnaire",
    ViewComponent: <QuestionnaireVersionViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version" },
      "pt-BR": { path: "fale-ingles/:version" },
      "en-US": { path: "speak-english/:version" },
      "it-IT": { path: "parla-inglese/:version" },
    },
  },
  {
    name: "question",
    ViewComponent: <QuestionnaireQuestionViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/:question" },
      "pt-BR": { path: "fale-ingles/:version/:question" },
      "en-US": { path: "speak-english/:version/:question" },
      "it-IT": { path: "parla-inglese/:version/:question" },
    },
  },
  {
    name: "questionnaire-update-email",
    ViewComponent: <QuestionnaireUpdateEmailViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/actualizar-email" },
      "pt-BR": { path: "fale-ingles/:version/atualizar-email" },
      "en-US": { path: "speak-english/:version/update-email" },
      "it-IT": { path: "parla-inglese/:version/aggiorna-email" },
    },
  },
  {
    name: "questionnaire-email-exists",
    ViewComponent: <QuestionnaireEmailExistsViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/email-ya-existe" },
      "pt-BR": { path: "fale-ingles/:version/email-ja-existe" },
      "en-US": { path: "speak-english/:version/email-exists" },
      "it-IT": { path: "parla-inglese/:version/email-esiste-già" },
    },
  },
  {
    name: "questionnaire-start-level-test",
    ViewComponent: <QuestionnaireStartLevelTestViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/empezar-test-de-nivel" },
      "pt-BR": { path: "fale-ingles/:version/teste-de-nivel-inicial" },
      "en-US": { path: "speak-english/:version/start-level-test" },
      "it-IT": { path: "parla-inglese/:version/test-di-livello-iniziale" },
    },
  },
  {
    name: "questionnaire-congratulations",
    ViewComponent: <QuestionnaireCongratulationsViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/enhorabuena" },
      "pt-BR": { path: "fale-ingles/:version/parabens" },
      "en-US": { path: "speak-english/:version/congratulations" },
      "it-IT": { path: "parla-inglese/:version/congratulazioni" },
    },
  },
  {
    name: "questionnaire-thank-you",
    ViewComponent: <QuestionnaireThankYouViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/gracias" },
      "pt-BR": { path: "fale-ingles/:version/obrigado" },
      "en-US": { path: "speak-english/:version/thank-you" },
      "it-IT": { path: "parla-inglese/:version/grazie" },
    },
  },
  {
    name: "questionnaire-new-confirm",
    ViewComponent: <QuestionnaireNewConfirmViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/nueva-confirmacion" },
      "pt-BR": { path: "fale-ingles/:version/nova-confirmacao" },
      "en-US": { path: "speak-english/:version/new-confirm" },
      "it-IT": { path: "parla-inglese/:version/nuova-conferma" },
    },
  },
  {
    name: "questionnaire-post-purchase",
    ViewComponent: <QuestionnairePostPurchaseViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/post-purchase" },
      "pt-BR": { path: "fale-ingles/:version/post-purchase" },
      "en-US": { path: "speak-english/:version/post-purchase" },
      "it-IT": { path: "parla-inglese/:version/post-purchase" },
    },
  },
  {
    name: "questionnaire-reactivate-or-new",
    ViewComponent: <QuestionnaireReactivateOrNewViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/reactivar-o-nueva" },
      "pt-BR": { path: "fale-ingles/:version/reativar-ou-novo" },
      "en-US": { path: "speak-english/:version/reactivate-or-new" },
      "it-IT": { path: "parla-inglese/:version/riattivare-o-nuovo" },
    },
  },
  {
    name: "questionnaire-reactivate",
    ViewComponent: <QuestionnaireReactivateViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/reactivar" },
      "pt-BR": { path: "fale-ingles/:version/reativar" },
      "en-US": { path: "speak-english/:version/reactivate" },
      "it-IT": { path: "parla-inglese/:version/riattivare" },
    },
  },
  {
    name: "questionnaire-next-step",
    ViewComponent: <QuestionnaireNextStepViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/siguiente-paso" },
      "pt-BR": { path: "fale-ingles/:version/proximo-passo" },
      "en-US": { path: "speak-english/:version/next-steps" },
      "it-IT": { path: "parla-inglese/:version/passaggi-successivi" },
    },
  },
  {
    name: "questionnaire-initial-level-test",
    ViewComponent: <QuestionnaireInitialLevelTestViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/test-de-nivel-inicial" },
      "pt-BR": { path: "fale-ingles/:version/teste-de-nivel-inicial" },
      "en-US": { path: "speak-english/:version/initial-level-test" },
      "it-IT": { path: "parla-inglese/:version/test-di-livello-iniziale" },
    },
  },
  {
    name: "questionnaire-login-with-password",
    ViewComponent: <QuestionnaireLoginWithPasswordViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/usuario" },
      "pt-BR": { path: "fale-ingles/:version/usuario" },
      "en-US": { path: "speak-english/:version/user" },
      "it-IT": { path: "parla-inglese/:version/utente" },
    },
  },
  {
    name: "questionnaire-verify-email",
    ViewComponent: <QuestionnaireVerifyEmailViewWrapper />,
    pathSets: {
      "es-419": { path: "habla-ingles/:version/verificar-email" },
      "pt-BR": { path: "fale-ingles/:version/verificar-email" },
      "en-US": { path: "speak-english/:version/verify-email" },
      "it-IT": { path: "parla-inglese/:version/verifica-email" },
    },
  },
  {
    name: "payment-failed",
    ViewComponent: <PaymentFailureViewWrapper />,
    pathSets: {
      "es-419": { path: "pago/fallido" },
      "pt-BR": { path: "pagamento/falha" },
      "en-US": { path: "payment/failed" },
      "it-IT": { path: "pagamento/fallito" },
    },
  },
];

export default definititions;
