export type CountryType =
  | "US"
  | "MX"
  | "AR"
  | "EC"
  | "SV"
  | "PA"
  | "PR"
  | "BO"
  | "CL"
  | "CO"
  | "CR"
  | "DO"
  | "GT"
  | "HN"
  | "NI"
  | "PY"
  | "PE"
  | "UY"
  | "VE";

// locales
export type LocaleType = "es-419" | "pt-BR" | "en-US" | "it-IT" | "de-DE";
export const FallbackLocale: LocaleType = "es-419";

const localesFromEnv = import.meta.env.VITE_PUBLIC_LOCALES?.split(
  ",",
) as LocaleType[];

// this is a list of allowed locales
export const Locales: LocaleType[] = localesFromEnv || [
  "es-419",
  "pt-BR",
  "en-US",
  "it-IT",

  // add these after routing table has been updated
  //"de-DE",
];
console.info(`Supported locales are: ${Locales}`);

export type TranslateFn = (key: string) => string;

export type TranslationModel = {
  [key in string]: string;
};

// how each locale is converted to an url param, for example es-419 --> /es-latam
export const LocalePathMap: Record<LocaleType, string> = {
  "en-US": "en",
  "pt-BR": "pt-br",
  "es-419": "es-latam",
  "it-IT": "it",
  "de-DE": "de",
};

// this is used to create aliases, for example /es/ --> /es-latam
export const LocalePathAliases: Record<LocaleType, string[]> = {
  "es-419": ["es", "es-419"],
  "pt-BR": ["pt"],
  "en-US": ["en-us"],
  "it-IT": ["it-it"],
  "de-DE": ["de-de"],
};

export type PaddleLocaleType = "es" | "en" | "pt" | "it" | "de";

export const PaddleLocaleMap: Record<LocaleType, PaddleLocaleType> = {
  "en-US": "en",
  "es-419": "es",
  "pt-BR": "pt",
  "it-IT": "it",
  "de-DE": "de",
};

export type DayJSLocaleType = "es-mx" | "en-us" | "pt-br" | "it" | "de";

// make sure these are loaded in App.tsx
export const DayJSLocaleMap: Record<LocaleType, DayJSLocaleType> = {
  "en-US": "en-us",
  "es-419": "es-mx",
  "pt-BR": "pt-br",
  "it-IT": "it",
  "de-DE": "de",
};
