import { MediaExtension, MediaSchema } from "lib/media";
import { Fragment, FunctionComponent } from "react";
import responsive from "theme/devices";
import { StyledImage, StyledPicture } from "./image.styles";
import { useMedia } from "hooks/media";
import { getType, ImagePriorityType } from "./list.styles";

interface ImageProps {
  className?: string;
  schema: MediaSchema;
  alt?: string;
  css?: any;
  priority?: ImagePriorityType;
  onClick?: () => Promise<void>;
}

const Image: FunctionComponent<ImageProps> = ({
  className,
  schema,
  alt,
  css,
  priority = "auto",
  onClick,
}) => {
  const {
    filename,
    extension,
    tablet = false,
    desktop = false,
    localised = false,
  } = schema;

  // do ne need webp
  const wepb = extension === "png";

  // get urls
  const mobileSrc = useMedia(filename, "mobile", extension, localised);
  const mobileWebpSrc = useMedia(filename, "mobile", "webp", localised);
  const tabletSrc = useMedia(filename, "tablet", extension, localised);
  const tabletWebpSrc = useMedia(filename, "tablet", "webp", localised);
  const desktopSrc = useMedia(filename, "desktop", extension, localised);
  const desktopWebpSrc = useMedia(filename, "desktop", "webp", localised);

  // NOTE: the order of the images is important due min-width rule
  return (
    <StyledPicture onClick={onClick}>
      {/* desktop image */}
      {desktop && (
        <Fragment>
          {wepb && generateSrcTag(responsive.desktop, desktopWebpSrc, "webp")}
          {generateSrcTag(responsive.desktop, desktopSrc, extension)}
        </Fragment>
      )}

      {/* tablet image */}
      {tablet && (
        <Fragment>
          {wepb && generateSrcTag(responsive.tablet, tabletWebpSrc, "webp")}
          {generateSrcTag(responsive.tablet, tabletSrc, extension)}
        </Fragment>
      )}

      {/* mobile image */}
      {wepb && generateSrcTag(responsive.mobile, mobileWebpSrc, "webp")}
      {generateSrcTag(responsive.mobile, mobileSrc, extension)}

      {/* fallback image */}
      <StyledImage
        src={mobileSrc}
        alt={alt}
        $css={css}
        className={className}
        fetchPriority={priority}
      />
    </StyledPicture>
  );
};

export default Image;

// generates a <source /> tag
const generateSrcTag = (
  media: string,
  srcSet: string,
  extension: MediaExtension,
) => {
  return <source media={media} srcSet={srcSet} type={getType(extension)} />;
};
