import { usePageLocale } from "hooks/route";
import { FunctionComponent, useEffect, useState } from "react";

interface LegalContentProps {
  type: "privacy-policy" | "licenses" | "terms-of-use";
}

const LegalContent: FunctionComponent<LegalContentProps> = ({ type }) => {
  const locale = usePageLocale();
  const [__html, toggleHTML] = useState("");

  useEffect(() => {
    const update = async () => {
      try {
        console.info(locale);
        const newHtml = await import(`./material/${type}-${locale}.html?raw`);
        console.info(newHtml);
        toggleHTML(newHtml.default);
      } catch (err) {
        console.error(err);
        toggleHTML("");
      }
    };
    update();
  }, [locale]);

  return <div dangerouslySetInnerHTML={{ __html }} />;
};

export default LegalContent;
