import { RouteObject } from "react-router";
import { RouteDefinition, RouteHandle } from "./definitions";
import NotFoundViewWrapper from "views/not-found/wrapper";
import {
  LocalePathAliases,
  LocalePathMap,
  Locales,
  LocaleType,
} from "models/locale";
//import DetectComponent from "./detect";
import Redirect from "./redirect";

const routeBuilder = (routeDefinitions: RouteDefinition[]): RouteObject[] => {
  const routes: RouteObject[] = [];

  routeDefinitions.forEach((definitition) => {
    const {
      name,
      ViewComponent,
      pathSets,
      sitemap = false,
      indexable = false,
      root = undefined,
    } = definitition;
    const { detect = undefined } = definitition;

    // rooted routes (mainly for links deep coded to mobile app)
    if (root) {
      routes.push({
        path: `/${root}/`,
        element: ViewComponent,
        handle: { name, sitemap, indexable },
      });
      return;
    }

    // if we need to detect the language for this route, add a special route (NOTE: route without prefix is skipped later)
    if (detect !== undefined) {
      // NOTE: FOR NOW THE DETECT MECHANISM IS DISABLED AND THIS CASE WILL REDIRECT TO ES-419 ROOT
      const canonical = `${import.meta.env.VITE_PUBLIC_ME_URL}/${LocalePathMap["es-419"]}/`;
      routes.push({
        path: `/`,
        element: ViewComponent,
        handle: { name, sitemap, indexable, canonical },
      });

      /*       if (detect !== "") {
        detect = `${detect}/`;
      }
      
      routes.push({
        path: `/${detect}/`,
        element: <DetectComponent definition={definitition} />,
        handle: { name, visible: false },
      });
 */
    }

    // create a route definition for every locale version
    for (const uncastedLocale in pathSets) {
      const locale = uncastedLocale as LocaleType;

      // skip unsupported locales
      if (Locales.includes(locale) === false) {
        continue;
      }

      const set = pathSets[locale];
      if (set) {
        // standard route without any issues
        const { OverrideViewCompoment } = set;
        let { path } = set;
        const localePath = LocalePathMap[locale];

        // add trailing slash for all routes BUT NOT THE ROOT
        if (path !== "") {
          path = `${path}/`;
        }

        // add normal prefixed route
        routes.push({
          path: `/${localePath}/${path}`,
          element: OverrideViewCompoment || ViewComponent,
          handle: { name, locale, sitemap, indexable } as RouteHandle,
        });

        // if detect mode is not defined, add non-prefixed route
        if (detect === undefined) {
          if (locale === "es-419") {
            // spanish is not redirected for now
            const canonical = `${import.meta.env.VITE_PUBLIC_ME_URL}/${localePath}/${path}`;
            routes.push({
              path: `/${path}`,
              element: OverrideViewCompoment || ViewComponent,
              handle: {
                name,
                locale,
                canonical,
                visible: false,
                sitemap: false,
              } as RouteHandle,
            });
          } else {
            // others are redirected
            routes.push({
              path: `/${path}`,
              element: <Redirect to={`/${localePath}/${path}`} />,
              handle: {
                name,
                locale,
                // redirect route is not to be shown om sitemaps
                visible: false,
                sitemap: false,
              } as RouteHandle,
            });
          }
        }

        // add routes with alias prefix
        const aliases = LocalePathAliases[locale];
        for (const alias of aliases) {
          routes.push({
            path: `/${alias}/${path}`,
            element: <Redirect to={`/${localePath}/${path}`} />,
            handle: {
              name,
              locale,
              // redirect route is not to be shown in sitemaps
              visible: false,
              sitemap: false,
            } as RouteHandle,
          });
        }
      }
    }
  });

  // add 404
  routes.push({
    path: "*",
    element: <NotFoundViewWrapper />,
    handle: { indexable: false, sitemap: false } as RouteHandle,
  });

  console.info(routes);
  return routes;
};

export default routeBuilder;
